import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import achieve from '../images/achieve.jpg';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Navigation from './nav';
import Footer from './footer.js';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
    


 function Achievements() {
  window.scrollTo(0, 0)
 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [description, setDescription] = useState("")
 const [hero, setHero] = useState("") 
 const [achievementBlock, setAchievementBlock] = useState([])
 const [awardBlock, setAwardBlock] = useState([])



  useEffect( () => {
    client
    .query({
    query: gql`
     query{
      pages {
        edges {
          node {
            achievements {
              achievementblock {
                achievementdetails
                achievementheading
                achievementsubheading
              }
              awardscard {
                awardingbody
                awardname
                image {
                  mediaItemUrl
                }
              }
              blacktextheading
              hero {
                mediaItemUrl
              }
              pagedescription
              redtextheading
            }
          }
        }
      }
    }
    `,
    })
    .then(async result => {
    let achievementHolder=[]
    let awardsHolder=[]
     console.log(result)
    //alert(result.data.vehicles.edges.length)  

     setRedText(result.data.pages.edges[5].node.achievements.redtextheading)
     setBlackText(result.data.pages.edges[5].node.achievements.blacktextheading)
     setHero(result.data.pages.edges[5].node.achievements.hero.mediaItemUrl)
     setDescription(result.data.pages.edges[5].node.achievements.pagedescription)

     for(let x=0; x < result.data.pages.edges[5].node.achievements.achievementblock.length; x++ ){
          
      let achievementDetails = [{heading:result.data.pages.edges[5].node.achievements.achievementblock[x].achievementheading, subheading:result.data.pages.edges[5].node.achievements.achievementblock[x].achievementsubheading, details:result.data.pages.edges[5].node.achievements.achievementblock[x].achievementdetails}]

      achievementHolder.push(achievementDetails)
     }

     for(let x=0; x < result.data.pages.edges[5].node.achievements.awardscard.length; x++ ){
          
      let awardsDetails = [{image:result.data.pages.edges[5].node.achievements.awardscard[x].image.mediaItemUrl, name:result.data.pages.edges[5].node.achievements.awardscard[x].awardname, body:result.data.pages.edges[5].node.achievements.awardscard[x].awardingbody}]

      awardsHolder.push(awardsDetails)
     }
     //console.log(leadersHolder)
     setAchievementBlock(achievementHolder)
     setAwardBlock(awardsHolder)
    // console.log(leaders)
    
    });
    
    }, [])   
    
    
    let achievementDetailsPack
    if(achievementBlock.length > 0){ 
  
    achievementDetailsPack = achievementBlock.map((achieve, i) => 
      <div className="accordion-item accordion-comp">
    <h2 className="accordion-header accordion-comp-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseAchieve${i}`} aria-expanded="true" aria-controls="collapseAchieve">
    <h5 className="accordion-comp-title"> {achieve[0].heading} <br/>
    <p className="lightFont marginOff"> {achieve[0].subheading}</p>  
    </h5> 
    
      </button>
    </h2>
    <div id={`collapseAchieve${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {achieve[0].details}
      </div>
    </div>
  </div>
    
    )

  }

  let awardsDetailsPack
  if(awardBlock.length > 0){ 

  awardsDetailsPack = awardBlock.map((award, i) => 
<div className="card card-static inlineMargin" style={{width: "16rem"}}>
  <img src={award[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title">{award[0].name} </h2>
    <p className="card-text"> by {award[0].body} </p>
        </div>

      </div>
  
  )

}

  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name">{blackText}<span className="color-red"> {redText} </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                        {description} 
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Some Achievments  </strong> </h1>

</div>
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray3"></div>


<div className="col-12 inlineDisplay"> 

<div className="accordion" id="accordionExample">
{achievementDetailsPack}



</div>

</div>
</div>
</div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 col-md-6"> 
<h1 className="basic-panel__content--title">Our <span className="lightFont"> awards</span> </h1>

</div>
<div className="component-rich-text__wrapper">

<div className="bk-blue-gray"></div>



<div className="row inlineDisplay"> 


{awardsDetailsPack}



</div>
</div>
</div>
</section>

<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default Achievements;