import "bootstrap/dist/css/bootstrap.css";
import { useState, useEffect } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import test from '../images/cover.jpg';
import Lana from '../images/lana.jpg';
import hospital from '../images/hospital.jpg';
import simulate from '../images/simulate.png';
import corp from '../images/corpo.jpg';
import workforce from '../images/workforce.jpg';
import keyman from '../images/keyman.jpg';
import edu from '../images/edu.jpg'
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg'
import Footer from './footer.js';
import Navigation from './nav';

import {
        InMemoryCache,
        ApolloClient,
        gql,
      } from '@apollo/client';
      
      const client = new ApolloClient({
        cache:  new InMemoryCache(),
        uri: "https://pzl-cms.inspireleadership.biz/graphql"
      });
        
     
    
     function Corporate() {
        window.scrollTo(0, 0)
     const [redText, setRedText] = useState("")
     const [blackText, setBlackText] = useState("")
     const [description, setDescription] = useState("")
     const [hero, setHero] = useState("") 
     const [benefitsBlock, setBenefitsBlock] = useState([])
     const [productsBlock, setProductsBlock] = useState([])
    
     const params = useParams()
    
      useEffect( () => {
        client
        .query({
        query: gql`
         query{
            pages {
                edges {
                  node {
                    corporate {
                      blackheadingtext
                      redheadingtext
                      productspotlight {
                        boldtextheading
                        lighttextheading
                        link
                        linkname
                        heroimage {
                          mediaItemUrl
                        }
                      }
                      heroimage {
                        mediaItemUrl
                      }
                      whyprudential {
                        cardcontent
                        cardheading
                        cardimage {
                          mediaItemUrl
                        }
                      }
                    }
                  }
               }
            }
        }
        `,
        })
        .then(async result => {
        let benefitsHolder=[]
        let productsHolder=[]
         console.log(result)
        
    
         setRedText(result.data.pages.edges[8].node.corporate.redheadingtext)
         setBlackText(result.data.pages.edges[8].node.corporate.blackheadingtext)
         setHero(result.data.pages.edges[8].node.corporate.heroimage.mediaItemUrl)
         setDescription(result.data.pages.edges[8].node.corporate.pagedescription)

         for(let x=0; x < result.data.pages.edges[8].node.corporate.whyprudential.length; x++ ){
          
         let benefitsDetails = [{heading:result.data.pages.edges[8].node.corporate.whyprudential[x].cardheading, subheading:result.data.pages.edges[8].node.corporate.whyprudential[x].cardcontent, image:result.data.pages.edges[8].node.corporate.whyprudential[x].cardimage.mediaItemUrl}]
            benefitsHolder.push(benefitsDetails)
         }
      
         for(let x=0; x < result.data.pages.edges[8].node.corporate.productspotlight.length; x++ ){
                
            let productDetails = [{image:result.data.pages.edges[8].node.corporate.productspotlight[x].heroimage.mediaItemUrl, boldText:result.data.pages.edges[8].node.corporate.productspotlight[x].boldtextheading, lightText:result.data.pages.edges[8].node.corporate.productspotlight[x].lighttextheading, linkname:result.data.pages.edges[8].node.corporate.productspotlight[x].linkname, link:result.data.pages.edges[8].node.corporate.productspotlight[x].link}]
            productsHolder.push(productDetails)

         }

         setBenefitsBlock(benefitsHolder)
         setProductsBlock(productsHolder)
       

        // console.log(leaders)
        
        });
        
        }, [])   



        let benefitsDetails

        if(benefitsBlock.length > 0 ){ 

            benefitsDetails = benefitsBlock.map((benefit, i) => 

            <div className="card card-static inlineMargin carouselCard paddingOff" >
  <img src={benefit[0].image} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {benefit[0].heading} </h2>
    <p className="card-text"> {benefit[0].subheading} </p>
    </div>

      </div>
        
        )
        }



        let productsDetails

        if(productsBlock.length > 0){ 
        
    
             productsDetails = productsBlock.map((product, i) => 
                i % 2 === 0 ?  
            <section className="section pd-100-60 basic-panel promotion" data-pru-link="">
          <div className="row clearSpaceBottom1 d-none d-md-flex">
          
          <div className="col-12 col-md-5 paddingOff"> 
                                      <div className="basic-panel__content-outer textGroupPosition">
                                          <div className="container">
                                              <div className="row">
                                                  <div className="basic-panel__content">
                                                      <div className="basic-panel__content--wrapper">
                                                          <h2 className="basic-panel__content--title">
                                                              <a name="easyswitch" id="easyswitch"></a>
                                                              <h1 className="bannerText basic-panel__content--title"> {product[0].boldText} <span className="lightFont">{product[0].lightText}</span> </h1>
                                                              
                                                          </h2>
          
          
                                                   
          
                                                                  <a className="btn-link gtm_PromotionBanner link" href={product[0].link} title="Learn more"> {product[0].linkname} <img src={arrow} />  </a>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      </div>
          
          <div className="col-12 col-md-7"> 
                          <div className="basic-panel__wrapper">
                              <div className="container">
                                      <div className="basic-panel__outer1">
                                          <div className="basic-panel__outer--img">
                                              <picture>
                                                  <source media="(max-width: 992px)" data-srcset=""/>
                                                      <img className=" ls-is-cached lazyloaded" src={product[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                              </picture>
                                          </div>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                           
                          
                     
                          </div>

                          <div className="row clearSpaceBottom1 d-block d-md-none">

<div className="col-12 col-md-7"> 
                <div className="basic-panel__wrapper">
                    <div className="container">
                            <div className="basic-panel__outer">
                                <div className="basic-panel__outer--img">
                                    <picture>
                                        <source media="(max-width: 992px)" data-srcset=""/>
                                            <img className=" ls-is-cached lazyloaded" src={product[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                    </picture>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-12 col-md-5 paddingOff"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                        <div className="basic-panel__content">
                                            <div className="basic-panel__content--wrapper">
                                                <h2 className="basic-panel__content--title">
                                                    <a name="easyswitch" id="easyswitch"></a>
                                                    <h1 className="bannerText basic-panel__content--title"> {product[0].boldText} <span className="lightFont">{product[0].lightText}</span> </h1>
                                                    
                                                </h2>


                                         

                                                        <a className="btn-link gtm_PromotionBanner link" href={product[0].link} title="Learn more"> {product[0].linkname} <img src={arrow} /> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                
           
                </div>
                      </section> :


<section className="section pd-100-60 basic-panel promotion" data-pru-link="">
<div className="row clearSpaceBottom1">

<div className="col-12 col-md-7"> 
                <div className="basic-panel__wrapper">
                    <div className="container">
                            <div className="basic-panel__outer">
                                <div className="basic-panel__outer--img">
                                    <picture>
                                        <source media="(max-width: 992px)" data-srcset=""/>
                                            <img className=" ls-is-cached lazyloaded" src={product[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                    </picture>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-12 col-md-5 paddingOff"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                        <div className="basic-panel__content">
                                            <div className="basic-panel__content--wrapper">
                                                <h2 className="basic-panel__content--title">
                                                    <a name="easyswitch" id="easyswitch"></a>
                                                    <h1 className="bannerText basic-panel__content--title"> {product[0].boldText} <span className="lightFont">{product[0].lightText}</span> </h1>
                                                    
                                                </h2>


                                         

                                                        <a className="btn-link gtm_PromotionBanner link" href={product[0].link} title="Learn more"> {product[0].linkname} <img src={arrow} /> </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                
           
                </div>
            </section>







                      
                      
          
          )
      
        }
      



  return(
  
    
  
<div className="">
<header> 
   <Navigation data={params.corporate}/>
      </header>

<section data-pru-link="" class="pd-100-60 section page-title">
					  	<div class="page-title__page-wrapper row">
                          <div class="col-md-5">
							<div class="page-title__content-outer">
											<div class="container">
											  <div class="row">
											
													<div class="page-title__content">
														<h1 class="page-title__content--name">{blackText}<span class="color-red"> {redText} </span>
														</h1>
														<div class="page-title__content--desc"> Taking a birds eye view, Prudential Zenith creates insurance products to manage
                                                        any risk to your business continuity. From goods to service delivery, we have a product suitable for all scenarios.
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div class="offset-md-1 col-md-6">
										  <div class="page-title__img-outer">
											<div class="row">
										
											      <div class="page-title__img-inner">
													 <picture>
													   <img class="page-title--img lazyloaded" width="100%" src={hero} data-src="/export/sites/prudential-pamb/en/.galleries/images/men-business-discussion-666x500.jpg" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>



<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-6 col-md-6 paddingBottom"> 
<h1 className="basic-panel__content--title"> Why <span className="lightFont"> Prudential Zenith </span> </h1>

</div>

<div className="row inlineDisplay"> 
{benefitsDetails}
</div>
</div>
</div>
</section>

<div className="container widthOverride"> 

{productsDetails}

</div>





<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default Corporate;