import "bootstrap/dist/css/bootstrap.css";
import "./footer.css";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import img from "../images/pru-img.jpg";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineChevronRight } from "react-icons/md";
import { IoChevronDownSharp } from "react-icons/io5";
import { GoPlus } from "react-icons/go";
import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
    


export default function Footer() {


let lifeblogArray = []
let healthblogArray = []
let marketsblogArray = []

const [lifeBlogs, setLifeBlogs ] = useState([])
const [healthBlogs, setHealthBlogs] = useState([])
const [marketBlogs, setMarketBlogs] = useState([])


useEffect( () => {   

  client
  .query({
  query: gql`
  query{
    allBlogPages {
      edges {
        node {
          id
          categories {
            edges {
              node {
                name
              }
            }
          }
          blogSingle {
            blogheading
          }
        }
      }
    }
  }
 `,

  })
  .then(async result => {
    console.log(result)
    
    
    let lifeblogDetailPack = []
    let healthblogDetailPack = []
    let marketsDetailPack = []
    lifeblogArray = []
  
    let x = 0
    
     // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

     if ( x < result.data.allBlogPages.edges.length - 1){

       for(x ; x < result.data.allBlogPages.edges.length; x++){ 
          //console.log(x)
   //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
         
        if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'lifeblog'){

          if(lifeblogArray.length < 1){
            lifeblogDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading }]
            lifeblogArray.push(lifeblogDetailPack)
          }
     
        }

        if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'healthblog'){

          if(healthblogArray.length < 1){
          healthblogDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading}]
          healthblogArray.push(healthblogDetailPack)
          }

        }

        if (result.data.allBlogPages.edges[x].node.categories.edges[0].node.name == 'Markets'){

          if(marketsblogArray.length < 1){
          marketsDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading}]
          marketsblogArray.push(marketsDetailPack)
          }

        }
             
         
           
   }



   setLifeBlogs(lifeblogArray)
   setHealthBlogs(healthblogArray)
   setMarketBlogs(marketsblogArray)
    console.log(lifeBlogs)
    console.log(healthBlogs)
    console.log(marketBlogs)

    }

      
  })


}, []) 


let lifeBlogDetails
let lifeBlogDetailsMob

if(lifeBlogs.length > 0){ 

  lifeBlogDetails = lifeBlogs.map((blog, i) => 
 
  <li> <Link to={`/blog/life-insurance/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </li>
  
  )

  lifeBlogDetailsMob = lifeBlogs.map((blog, i) => 
 
    <p> <Link to={`/blog/life-insurance/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </p>
    
    )



}



let healthBlogDetails
let healthBlogDetailsMob

if(healthBlogs.length > 0){ 

healthBlogDetails = healthBlogs.map((blog, i) => 

  <li> <Link to={`/blog/health-and-wellness/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </li>

)

healthBlogDetailsMob = healthBlogs.map((blog, i) => 

  <p> <Link to={`/blog/health-and-wellness/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </p>

)

}


let marketBlogDetails
let marketBlogDetailsMob


if(marketBlogs.length > 0){ 

marketBlogDetails = marketBlogs.map((blog, i) => 

  <li> <Link to={`/blog/markets/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </li>

)

marketBlogDetailsMob = marketBlogs.map((blog, i) => 

  <p> <Link to={`/blog/markets/${blog[0].id}`} className="link1">  {blog[0].heading}   </Link> </p>

)


}



  return (
    <>

    <div className="container">


      <div class="row cl d-none d-sm-block">
        <div class=" d-flex justify-content-around ps-4 pe-4 pt-2 ">
          <div class="p-cl">
            <ul>
              <li class="footer-hd"> All You Need to Know About Insurance </li>
              {marketBlogDetails}
              {healthBlogDetails}
              {lifeBlogDetails}
            
            
            </ul>
          </div>
          <div class="p-cl">
            <ul>
              <li class="footer-hd">Our Products</li>
              <li> <a href="/medical/home" className="link1">  Medical Insurance </a> </li>
                  <li> <a href="/life/home" className="link1"> Life Insurance </a> </li>
                  <li> <a href="/family/home" className="link1"> Family Insurance </a> </li>
                  <li> <a href="/education/home" className="link1">  Education Insurance </a> </li>
                  <li> <a href="/corporate/home" className="link1">  Corporate Insurance </a> </li>
            </ul>
          </div>
          <div class="p-cl">
            <ul>
              <li class="footer-hd">Services & Claims</li>
              <li> <a href="/medical/home" className="link1">  Claims Forms </a> </li>
                  <li> <a href="/life/home" className="link1"> Claims FAQ </a> </li>
                  <li> <a href="/family/home" className="link1"> Claims Promise </a> </li>
                  <li> <a href="/education/home" className="link1">  Claims Glossary </a> </li>
     
            </ul>
          </div>
          <div class="p-cl">
            <ul>
              <li class="footer-hd">Legal & regulatory related</li>
              <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>Client Charter</li>
            </ul>
          </div>
          <div class="p-cl">
            <ul>
              <li class="footer-hd">About Us & Careers</li>
              <li> <a href="/about" className="link1">    Prudential Zenith Life    </a> </li>
                  <li> <a href="/about/leadership" className="link1">     Leadership     </a> </li>
                  <li> <a href="/about/achievements" className="link1">  Achievements  </a> </li>
                  <li> <a href="/about/statements" className="link1">  Financials  </a> </li>
            </ul>
          </div>
          <div class="p-cl">
            <ul>
              <li class="footer-hd">Prudential Zenith </li>
              <li>
              6th Floor, Civic Towers <br /> Ozumba Mbadiwe Street <br />
             Victoria Island Lagos <br />   101241
              </li>
              <li>Customer Service:</li>
              <li>02018887600</li>
            </ul>
          </div>
        </div>
      </div>
      <div class=" d-none d-sm-block bottom-footer row">
        <div class=" container-fluid d-flex justify-content-between socio-div ">
          <div class=" ms-5 socio-txt ">
            <img class="footer-img" src={img} />
            <br />
            <br />
            <p>
            Click here to find out more about PIDM's TIPS
            <br /> <br /> Prudential Zenith Life Insurance is an indirect{" "}
            <br />
            subsidiary of Prudential plc. Neither Prudential Zenith Life
            <br /> Insurance nor Prudential plc is affiliated in any
            <br /> manner with Prudential Financial, Inc, a company whose <br />
            principal place of business is in the United States of <br />{" "}
            America or with Prudential Assurance Company, a <br /> subsidiary of
            M&G plc, a company incorporated in the <br /> United Kingdom.
          </p>

          <p class="btm-ft-p mt-4">
            Copyright © 2024 Prudential Zenith Life Insurance <br />{" "}
            All rights reserved.
          </p>

         
          </div>

          <div class="socio-icn ">
          <a href="https://www.instagram.com/prudentialzenithofficial/" target="_blank">
              <FaInstagram class="socio" size={50} />
            </a>
            <a href="https://web.facebook.com/Prudentialzenithlife" target="_blank">
            <FaFacebookF class="socio" size={50} />
            </a>
            <a href="https://www.youtube.com/channel/UCfDNCCRxLvnmG0a8OR-jz5w" target="_blank">
            <FaYoutube class="socio" size={50} />
            </a>
            <a href="https://www.linkedin.com/company/prudential-zenith-life-insurance/" target="_blank">
            <FaLinkedin class="socio  " size={50} />
            </a>
          </div>
          <p class="btm-ft-p mt-4">
            Web Development & Security by iNspire
          </p>
        </div>
    
       
      </div>

      <div class="remove d-block d-sm-none">
        <div class="row">
          <div class="gap-1 gp container-fluid">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#All-you-need"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">All you need to know about Insurance</div>
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="All-you-need">
              <div class="mb-p">
              {marketBlogDetailsMob}
              {healthBlogDetailsMob}
              {lifeBlogDetailsMob}
              </div>
            </div>
          </div>
          <div class="gap-1 gp">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#Our-products"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">Our Products</div>
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="Our-products">
              <div>
                <div class="mb-p">
                  <p> <a href="/medical/home" className="link1">  Medical Insurance </a> </p>
                  <p> <a href="/life/home" className="link1"> Life Insurance </a> </p>
                  <p> <a href="/family/home" className="link1"> Family Insurance </a> </p>
                  <p> <a href="/education/home" className="link1">  Education Insurance </a> </p>
                  <p> <a href="/corporate/home" className="link1">  Corporate Insurance </a> </p>
            
                </div>
              </div>
            </div>
          </div>
          <div class="gap-1 gp">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#Services-claims"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">Services & Claims </div>
              
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="Services-claims">
              <div>
                <div class="mb-p">
                <p> <a href="/claims/forms" className="link1">    Claims forms     </a> </p>
                  <p> <a href="/claims/faqs" className="link1">     Claims FAQs      </a> </p>
                  <p> <a href="/claims/promise" className="link1">  Claims Promise   </a> </p>
                  <p> <a href="/claims/glossary" className="link1"> Claims Glossary  </a> </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div class="gap-1 gp">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#Legal"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">Legal & regulaoty related </div>
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="Legal">
              <div>
                <div class="mb-p">
                  <p>Privacy Policy</p>
                  <p>Terms and Condition</p>
                  <p>Client Charter</p>
                </div>
              </div>
            </div>
          </div>
          <div class="gap-1 gp">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#About-us"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">About Us & Careers</div>
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="About-us">
              <div>
                <div class="mb-p">
                <p> <a href="/about" className="link1">    Prudential Zenith Life    </a> </p>
                  <p> <a href="/about/leadership" className="link1">     Leadership     </a> </p>
                  <p> <a href="/about/achievements" className="link1">  Achievements  </a> </p>
                  <p> <a href="/about/statements" className="link1">  Financials  </a> </p>
                </div>
              </div>
            </div>
          </div>
          <div class="gap-1 gp">
            <div
              class="d-flex justify-content-between align-items-center mb-nv"
              data-bs-toggle="collapse"
              href="#Pru-Nige"
              role="button"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div class="mb-d">Prudential Nigeria </div>
              <div>
                <GoPlus class="go-plus" />
              </div>
            </div>
            <div class="collapse" id="Pru-Nige">
              <div>
                <div class="mb-p d-flex justify-content-between align-items-center">
                <p>
              6th Floor Civic Towers <br /> Ozumba Mbadiwe Street <br />
             Victoria Island Lagos <br />   101241
              </p>
              <p>Customer Service:</p>
              <p>02018887600</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row btm-ft clearSpaceMini">
          <div class="soc-icn pt-4">
            <a href="https://www.instagram.com/prudentialzenithofficial/" target="_blank">
              <FaInstagram class="socio" size={50} />
            </a>
            <a href="https://web.facebook.com/Prudentialzenithlife" target="_blank">
            <FaFacebookF class="socio" size={50} />
            </a>
            <a href="https://www.youtube.com/channel/UCfDNCCRxLvnmG0a8OR-jz5w" target="_blank">
            <FaYoutube class="socio" size={50} />
            </a>
            <a href="https://www.linkedin.com/company/prudential-zenith-life-insurance/" target="_blank">
            <FaLinkedin class="socio  " size={50} />
            </a>
          </div>

          <img src={img} class="img" />

          <p class="ms-5 btm-ft-p mt-3">
            Click here to find out more about PIDM's TIPS
            <br /> <br /> Prudential Zenith Life Insurance is an indirect{" "}
            <br />
            subsidiary of Prudential plc. Neither Prudential Zenith Life
            <br /> Insurance nor Prudential plc is affiliated in any
            <br /> manner with Prudential Financial, Inc, a company whose <br />
            principal place of business is in the United States of <br />{" "}
            America or with Prudential Assurance Company, a <br /> subsidiary of
            M&G plc, a company incorporated in the <br /> United Kingdom.
          </p>

          <p class="ms-5 btm-ft-p mt-4">
            Copyright © 2024 Prudential Zenith Life Insurance <br />{" "}
            All rights reserved.
          </p>

          <div className="text-center whiteText lightFont"> 
            Web Development And Security By iNspire
        </div>
        </div>
        
      </div>

      
      </div>
    </>
  );
}
