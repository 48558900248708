import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import test from '../images/cover.jpg';
import Lana from '../images/lana.jpg';
import hospital from '../images/hospital.jpg';
import simulate from '../images/simulate.png';
import Footer from './footer.js';
import pzl from '../images/pzl.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Navigation from './nav';
import arrow from '../images/arrow.svg'
import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
  
  
  
  
  
  





 function Leadership() {
	window.scrollTo(0, 0)

	const[leaders, setLeaders] = useState('')
	
  
  useEffect( () => {
	client
  .query({
	query: gql`
	 query{
		pages {
			edges {
			  node {
				leadership {
				  leadership {
					name
					designation
					image {
					  mediaItemUrl
					}
				  }
				}
			  }
			}
	    }
	}
	`,
  })
  .then(async result => {
	let leadersHolder=[]
	 console.log(result)
	//alert(result.data.vehicles.edges.length)  
         for(let x=0; x < result.data.pages.edges[6].node.leadership.leadership.length; x++ ){
			  
			let leaderDetails = [{name:result.data.pages.edges[6].node.leadership.leadership[x].name, designation:result.data.pages.edges[6].node.leadership.leadership[x].designation, image:result.data.pages.edges[6].node.leadership.leadership[x].image.mediaItemUrl}]

			leadersHolder.push(leaderDetails)
		 }
		 console.log(leadersHolder)
		 setLeaders(leadersHolder)
		 console.log(leaders)
  
  });
  
  }, [])   
  
	
  let leaderDetailsPack
  if(leaders.length > 0){ 

	leaderDetailsPack = leaders.map((leader, i) => 
		<div className="card card-static inlineMargin mobile-center" style={{width: "20rem"}}>
	<img src={leader[0].image} width="100%" className="border-radius-card" alt="..."/>
	<div className="card-body card-static__content">
	  <h2 className="card-title card-static__content--title"> {leader[0].name}  </h2>
	  <p className="card-text"> {leader[0].designation} </p>
	   </div>
  
		</div>
  
  )

}
	
	
return(
  
    
  
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" class="pd-100-60 section page-title">
					  	<div class="page-title__page-wrapper row">
                          <div class="col-md-5">
							<div class="page-title__content-outer">
											<div class="container">
											  <div class="row">
											
													<div class="page-title__content">
														<h1 class="page-title__content--name"> Our Leaders <span class="color-red"> Decades of Success and Results </span>
														</h1>
														<div class="page-title__content--desc"> The leadership at Prudential know and show the team the best way to travel on our journey.
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div class="offset-md-1 col-md-6">
										  <div class="page-title__img-outer whiteBg">
											<div class="row">
										
											      <div class="page-title__img-inner whiteBg">
													 <picture>
													   <img class="page-title--img lazyloaded" width="100%" src={pzl} data-src="/export/sites/prudential-pamb/en/.galleries/images/men-business-discussion-666x500.jpg" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> Our Board of <span className="lightFont"> Directors </span> </h1>

</div>

<div className="row inlineDisplay"> 
 {leaderDetailsPack}
</div>
</div>
</div>
</section>






<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default Leadership;