import "bootstrap/dist/css/bootstrap.css";
import { useState, useEffect } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import health from '../images/health.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Navigation from './nav';
import Footer from './footer.js';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });


 function BlogLanding() {
  window.scrollTo(0, 0)
  const params = useParams()

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [description, setDescription] = useState("")
 const [hero, setHero] = useState("") 
 const [subheading, setSubheading] = useState("")
 const [blogs, setBlogs] = useState("")
 

 let blogsArray = []



  useEffect( () => {
     client
     .query({
       query: gql`
       query($id: String){
        allBlogGroup(where:{categoryName:$id}){
          edges {
            node {
              blogLanding {
                bloggroupdescription
                bloggroupheading
                heroimage {
                  mediaItemUrl
                }
                subheading
              }
            }
          }
        }
     }
     `,
     variables: {id:params.section}
     })
     .then(async result => { 
      console.log(result)
  
        setBlackText(result.data.allBlogGroup.edges[0].node.blogLanding.bloggroupheading)
        setDescription(result.data.allBlogGroup.edges[0].node.blogLanding.bloggroupdescription)
        setHero(result.data.allBlogGroup.edges[0].node.blogLanding.heroimage.mediaItemUrl)
        setSubheading(result.data.allBlogGroup.edges[0].node.blogLanding.subheading)
        

   // }
  
     })

     client
     .query({
       query: gql`
       query($id:String){
        allBlogPages(where:{categoryName:$id}){
          edges {
            node {
              id
              blogSingle {
                blogcontent
                blogheading
                coverimage {
                  mediaItemUrl
                }
                covervideo
              }
            }
          }
        }
     }
     `,
     variables: {id:params.section}
     })
     .then(async result => { 
      console.log(result)
  
      
       let blogDetailPack 
       let x 

      // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

      if (blogsArray.length != result.data.allBlogPages.edges.length){
        for(x = 0; x < result.data.allBlogPages.edges.length; x++){ 
           //console.log(x)
    //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
      
              
           blogDetailPack = [{id:result.data.allBlogPages.edges[x].node.id, heading:result.data.allBlogPages.edges[x].node.blogSingle.blogheading, image:result.data.allBlogPages.edges[x].node.blogSingle.coverimage.mediaItemUrl }]
           blogsArray.push(blogDetailPack)
            
    }

  }

    setBlogs(blogsArray)

   // }
        
     })
  
  
  }, [])  


  let blogDetails

  if(blogs.length > 0){ 
  
    blogDetails = blogs.map((blog, i) => 
<div className="card card-static inlineMargin paddingOff carouselCard" style={{width: "25rem"}}>
  <Link to={`/blog/${blackText}/${blog[0].id}`} className="link"> 
  <img src={blog[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {blog[0].heading} </h2>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </Link>
      </div>
    
    )

  }

  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name"> {blackText} 
														</h1>
														<div className="page-title__content--desc"> 
                                                        {description}
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded img-fluid" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 

<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>
<div className="col-12 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  {blackText}  </strong> <span className="lightFont"> Articles </span> </h1>
<p> {subheading} </p>
</div>

<div className="row inlineDisplay"> 

{blogDetails}

</div>


</div>
</div>
</section>


<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default BlogLanding;