import "bootstrap/dist/css/bootstrap.css";
import $ from 'jquery';
import { useState, useEffect } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import affordable from '../images/affordable.jpg';
import preference from '../images/preference.jpg';
import protection from '../images/protect.jpg';
import protectionPlus from '../images/protect+.jpg';
import couple from '../images/couple.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';

import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });


 function PolicyLanding() {


  window.scrollTo(0, 0)

  $(".plans").click(function() {
    $('html,body').animate({
        scrollTop: $(".planning").offset().top},
        'fast');
  });
  

  const params = useParams()

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [description, setDescription] = useState("")
 const [hero, setHero] = useState("") 
 const [benefits, setBenefits] = useState("")
 const [benefitsSubheading, setBenefitsSubheading] = useState("")
 const [faqs, setFaqs] = useState("")
 const [marketing, setMarketing] = useState("")
 const [products, setProducts] = useState("")

  let benefitsArray =[]
  let faqsArray =[]
  let marketingArray =[]
  let productsArray = []

  useEffect( () => {
     client
     .query({
       query: gql`
       query($id: String){
        allPolicyGroup(where: {categoryName: $id}) {
          edges {
            node {
              policyLanding {
                blackheadingtext
                fieldGroupName
                policydescription
                redheadingtext
                policybenefits {
                  benefitssubheading
                  benefitscard {
                    benefitsexplanation
                    benefitsheading
                    benefitsimage {
                      mediaItemUrl
                    }
                  }
                }
                policyfaq {
                  question
                  solution
                }
                policyimage {
                  mediaItemUrl
                }
                policymarketing {
                  descriptiona
                  descriptionb
                  heading
                }
              }
            }
          }
        }
     }
     `,
     variables: {id: params.section}
     })
     .then(async result => { 
      console.log(result)
  
        setBlackText(result.data.allPolicyGroup.edges[0].node.policyLanding.blackheadingtext)
        setRedText(result.data.allPolicyGroup.edges[0].node.policyLanding.redheadingtext)
        setDescription(result.data.allPolicyGroup.edges[0].node.policyLanding.policydescription)
        setHero(result.data.allPolicyGroup.edges[0].node.policyLanding.policyimage.mediaItemUrl)
        setBenefitsSubheading(result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitssubheading)

      
       let benefitsDetailPack 
       let faqDetailPack
       let marketingDetailPack
       

       let x 

      // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

      if (benefitsArray.length != result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitscard.length){
        for(x = 0; x < result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitscard.length; x++){ 
           //console.log(x)
    //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
      
              
           benefitsDetailPack = [{heading:result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitscard[x].benefitsheading,body:result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitscard[x].benefitsexplanation, image:result.data.allPolicyGroup.edges[0].node.policyLanding.policybenefits[0].benefitscard[x].benefitsimage.mediaItemUrl }]
           benefitsArray.push(benefitsDetailPack)
            
    }

  }
    
    for(x = 0; x < result.data.allPolicyGroup.edges[0].node.policyLanding.policyfaq.length; x++){ 
      //console.log(x)
//   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
         
      faqDetailPack = [{question:result.data.allPolicyGroup.edges[0].node.policyLanding.policyfaq[x].question, solution:result.data.allPolicyGroup.edges[0].node.policyLanding.policyfaq[x].solution }]
      faqsArray.push(faqDetailPack)
       
   }


   if (marketingArray.length != result.data.allPolicyGroup.edges[0].node.policyLanding.policymarketing.length){
 for(x = 0; x < result.data.allPolicyGroup.edges[0].node.policyLanding.policymarketing.length; x++){ 
  //console.log(x)
//   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
  marketingDetailPack = [{heading:result.data.allPolicyGroup.edges[0].node.policyLanding.policymarketing[x].heading, descriptiona:result.data.allPolicyGroup.edges[0].node.policyLanding.policymarketing[x].descriptiona, descriptionb:result.data.allPolicyGroup.edges[0].node.policyLanding.policymarketing[x].descriptionb }]
  marketingArray.push(marketingDetailPack)
   
     }
   }


    setBenefits(benefitsArray)
    console.log(faqsArray)
    setFaqs(faqsArray)
    setMarketing(marketingArray)


   // }
      
      
  
     })







     client
     .query({
       query: gql`
       query($id: String){
        allPolicyPages(where: {categoryName:$id}) {
          edges {
            node {
              id
              policy {
                blackheadingtext
                productdescription
                productimage {
                  mediaItemUrl
                }
              }
            }
          }
        }
     }
     `,
     variables: {id:params.section}
     })
     .then(async result => { 
      console.log(result)
  
      
       let productDetailPack 
    
       

       let x 

      // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

      if (productsArray.length != result.data.allPolicyPages.edges.length){
        for(x = 0; x < result.data.allPolicyPages.edges.length; x++){ 
           //console.log(x)
    //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
      
              
           productDetailPack = [{id:result.data.allPolicyPages.edges[x].node.id, heading:result.data.allPolicyPages.edges[x].node.policy.blackheadingtext, description:result.data.allPolicyPages.edges[x].node.policy.productdescription, image:result.data.allPolicyPages.edges[x].node.policy.productimage.mediaItemUrl }]
           productsArray.push(productDetailPack)
            
    }

  }


    setProducts(productsArray)
   


   // }
      
      
  
     })
  
  
  }, [])   


  let marketingDetails

  if(marketing.length > 0){ 
  
    marketingDetails = marketing.map((market, i) => 
      <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
    <div className="container">
        

        <div className="component-rich-text__wrapper">
<div className="bk-blue-gray2"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h2 className="bannerText"> {market[0].heading} </h2>
<p className="editorial-body-copy">{market[0].descriptiona} </p>
<p className="editorial-body-copy">{market[0].descriptionb}</p>
</div>
</div>
</div>
</div>


    </div>
</section>
    
    )

  }


  let faqDetails

  if(faqs.length > 0){ 
  
    faqDetails = faqs.map((faq, i) => 
      <div className="accordion-item accordion-comp">
    <h2 className="accordion-header accordion-comp-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapsePolic${i}`} aria-expanded="true" aria-controls="collapsePolic">
    <h5 className="accordion-comp-title"> {faq[0].question}  
    </h5> 
    
      </button>
    </h2>
    <div id={`collapsePolic${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
      {faq[0].solution}
      </div>
    </div>
  </div>
    
    )

  }


  let benefitsDetails

  if(benefits.length > 0){ 
  
    benefitsDetails = benefits.map((benefit, i) => 
<div className="card card-static inlineMargin paddingOff carouselCard">
  <img src={benefit[0].image} width="100%" className="border-radius-card" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {benefit[0].heading} </h2>
    <p className="card-text"> {benefit[0].body} </p>
       </div>

      </div>
    
    )

  }


  let productDetails

  if(products.length > 0){ 
  
    productDetails = products.map((product, i) => 
<div className="card card-static inlineMargin paddingOff carouselCard">
  <Link to={`/policy/individual/${product[0].id}`} className="link"> 
  <img src={product[0].image} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> {product[0].heading} </h2>
    <p className="card-text"> {product[0].description} </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </Link>
      </div>
    
    )

  }



 

  return(
       
<div className="">

<header> 
   <Navigation  section={params.section}/>
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name">{blackText} <span className="color-red"> {redText} </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                       {description}
                                                        </div>


                                                        <button className="cta-primary hero-banner__content--btn plans">
            
                                                 <span> See all plans </span>
       
                                                        </button>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>


  {marketingDetails}




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Range of </strong> <span className="lightFont"> benefits </span> </h1>
<p> Secure your families happiness today with ease </p>

</div>

<div className="row inlineDisplay"> 


{benefitsDetails}

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container planning"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray-intro"></div>

<div className="col-12 col-md-6"> 
<h1 className="basic-panel__content--title"> Protection Plans  <span className="lightFont"> Designed for flexibility</span> </h1>
<p> Don't leave their future wellbeing to chance. With premium life insirance you can rest assured </p>
</div>

<div className="row inlineDisplay"> 
{productDetails}
</div>
</div>
</div>
</section>


{/*
<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Frequently Asked Questions  </strong> </h1>

</div>
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray3"></div>


<div className="col-12 inlineDisplay"> 

<div className="accordion" id="accordionExample">
 
 {faqDetails}



</div>

</div>
</div>
</div>
</section>



<div data-pru-link="" class="container">
                    <div class="contact-form row pd-100-60">
                       <div class="col-md-7 col-lg-6 contact-form__input">
                         <form id="form" data-submit-form="" data-url-submit="/system/modules/com.prudential.v2.pamb/action/lead-generation-form.jsp" data-analytics="{&quot;event&quot;:&quot;formStarts&quot;,&quot;formName&quot;:&quot;Lead Generation Form mm&quot;,&quot;formType&quot;:&quot;Lead Generation mm&quot;}" data-url-redirect="/en/thank-you/" data-method="POST" novalidate="">
                            <div class="title-form__wrapper">
                                <h1 class="title-form" data-product-title=""><span class="title-form__bold">Get In Touch With Us</span> </h1>
                                </div>
                             <div class="notification-form d-none" data-notification=""><span class="icon icon-warning-triangle notification-form__icon"></span>
                                <div class="notification-form__content">
                                  <div class="notification-form__title"> *Please fill in all fields</div>
                                  <div class="notification-form__desc" data-notification-message=""> *Please fill in all fields</div>
                                </div>
                             </div>

                             <p class="mandatory-text">All fields marked with * are mandatory</p>
        
                             <h2 class="group-field__title" data-required="">Are you a Prudential customer?</h2>
                             <fieldset class="section-radio-checkbox" data-radio-checkbox-popup="">
                                <legend class="sr-only">Are you a Prudential customer?</legend>
                                <div class="radio-checkbox__wrapper field-item">
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="yes">
                                                <input class="radio-checkbox__input" type="radio" name="policyRadios" id="yes" required="" data-parsley-required-message="This is required" value="Yes" data-parsley-multiple="policyRadios" /><span></span>Yes</label>
                                        </div>
                                    <div class="radio-checkbox">
                                            <label class="radio-checkbox__label" for="no">
                                            <input class="radio-checkbox__input" type="radio" name="policyRadios" id="no" value="No" data-parsley-multiple="policyRadios" />
                                                <span></span>
                                                No
                                                </label>
                                        </div>     
                                    </div>
                             
                                </fieldset>

                                <div class="section-input-form">
                                  <h2 class="group-field__title">Your details</h2>
                                 
                                    <div class="input-text-field">
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="text" name="first-name" id="first-name" required="" data-parsley-required-message="Please fill in your first name" />
                                              <label class="field-placeholder" for="first-name">First Name</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="text" name="last-name" id="last-name" required="" data-parsley-required-message="Please fill in your surname" />
                                               <label class="field-placeholder" for="last-name">Surname</label>
                                          </div>
                                        </div>
                                    </div>


                                    <div class="section-input-form">
                                  <h2 class="group-field__title">Preferred Mode of Contact</h2>
                                 
                                    <div class="input-text-field">
                                          <div class="field-item">
                                              <input class="field-input" placeholder=" " type="number" name="cell-no" id="cell-no" required="" data-parsley-required-message="Please fill in your cell no" />
                                              <label class="field-placeholder" for="first-name">Phone number</label>
                                          </div>
                                        </div>
                               
                                    <div class="input-text-field">
                                          <div class="field-item">
                                               <input class="field-input" placeholder=" " type="email" name="email" id="email" required="" data-parsley-required-message="Please fill email address" />
                                               <label class="field-placeholder" for="last-name">Email</label>
                                          </div>
                                        </div>
                                    </div>

                                    <a className="cta-primary hero-banner__content--btn" target="_blank" href="" title="Discover More">
            
                                      <span>  Submit  </span>
       
                                         </a>
                              

                             </form>
                             </div>
                             </div>
                             </div>
  */}




                             <footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default PolicyLanding;