import "bootstrap/dist/css/bootstrap.css";
import { useState, useEffect } from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Navigation from './nav';
import Footer from './footer.js';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
    

 function BlogSingle() {
	window.scrollTo(0, 0)
  const params = useParams()

 const [redText, setRedText] = useState("")
 const [blogHeading, setBlogHeading] = useState("")
 const [blogContent, setBlogContent] = useState("")
 const [hero, setHero] = useState("") 
 const [videoUrl, setVideoUrl] = useState("")
 


  useEffect( () => {
     client
     .query({
       query: gql`
       query($id:ID!){
		blogPagesBy(id:$id) {
			blogSingle {
			  blogcontent
			  blogheading
			  coverimage {
				mediaItemUrl
			  }
			  covervideo
			}
		  }
     }
     `,
     variables: {id:params.id}
     })
     .then(async result => { 
      console.log(result)
  
        setBlogHeading(result.data.blogPagesBy.blogSingle.blogheading)
       setBlogContent(result.data.blogPagesBy.blogSingle.blogcontent)

             setVideoUrl(result.data.blogPagesBy.blogSingle.covervideo)

			setHero(result.data.blogPagesBy.blogSingle.coverimage.mediaItemUrl)
	
        
        

   


      
     })

    }, [])   


	let videoPostContent

   
	if(!videoUrl ){
	 videoPostContent = <img src={hero} width="100%" className="img-fluid" />
	}else{
   
	 videoPostContent = 
	 <iframe width="100%" height="480" src={videoUrl} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
   
	}

	


  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" class="section page-title-noimg clearSpaceMini ">
				    <div class="page-title-noimg__wrapper">
          						<div class="container">
								   	<div class="row">
									   	<div class="col-xs-12 col-sm-8 offset-sm-2">
									      <h1 class="page-title-noimg__content--title">{blogHeading}<span class="color-black"></span>
										  </h1>
										  <div class="page-title-noimg__content--desc"></div>
										</div>
									</div>
								</div>
							</div>
					   </section>


					   <div className=" container clearSpaceMini"> 
					   
					     <div className="row"> 
						 
						    {videoPostContent}
							
						 </div>
					   </div>



                       <section class="section-rich-text" data-pru-link="" data-analytics="{&quot;event&quot;:&quot;anchorLinkClick&quot;}">
								<div class="container">
									<div class="row">
										<div class="rich-text col-md-12" data-rich-text="">

<p>    


{blogContent}

</p>


                                        </div>
									</div>
								</div>
							</section>


							<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default BlogSingle;