import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight, faFilePdf } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import press from '../images/press.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';


import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });


 function Press() {
	window.scrollTo(0, 0)
  const params = useParams()


 const [press, setPress] = useState("")
 

 let pressArray = []



  useEffect( () => {
     client
     .query({
       query: gql`
       query($id: String){
		allStatementsAndPress(where: {categoryName:$id}) {
			edges {
			  node {
				statementandpress {
					pressgroup {
						description
						heading
						heroimage {
						  mediaItemUrl
						}
						link
				}
			  }
			}
		  }
		}
     }
     `,
     variables: {id:'Press'}
     })
     .then(async result => { 
      console.log(result)
  
  
      
	  let pressDetailPack 
	  let x 

	 // if (newsArray.length != result.data.news.edges[0].node.categories.edges[0].node.news.edges.length){

	 if (pressArray.length != result.data.allStatementsAndPress.edges.length){
	   for(x = 0; x < result.data.allStatementsAndPress.edges.length; x++){ 
		  //console.log(x)
   //   alert(result.data.news.edges[0].node.categories.edges[0].node.categoryId)
	 
			 
		  pressDetailPack = [{ heading:result.data.allStatementsAndPress.edges[x].node.statementandpress.pressgroup[0].heading, image:result.data.allStatementsAndPress.edges[x].node.statementandpress.pressgroup[0].heroimage.mediaItemUrl, description:result.data.allStatementsAndPress.edges[x].node.statementandpress.pressgroup[0].description, link:result.data.allStatementsAndPress.edges[x].node.statementandpress.pressgroup[0].link }]
		  pressArray.push(pressDetailPack)
		   
   }

 }

   setPress(pressArray)

        
   // }
  
     })

	}, [])	 


	let pressDetails

	if(press.length > 0){ 
	
	  pressDetails = press.map((release, i) => 
		<div class="row search-results">
	  <div class="col-md-3 d-none d-md-flex">
		<a href={release[0].link} title="PRULink Funds Report 2018 (abridged version)" download="" data-keyword="" class="search-results__img no-img">
			<div class="icon icon-pdf">
			<img src={release[0].image} className="img-fluid" width="100%"/>
			</div>
		  </a>
	  </div>
		
	  <div class="col-md-7 search-results__content">
		<div class="search-results__content--sup">
		  <span class="color-red">Press release</span>
		  <span class="size">
			
			</span>
		  <span class="size">
		
			</span>
		</div>
		<a href="/en/.galleries/PDF/fund/Abridge_PRULink-Funds-Report-2018.pdf" download="" data-keyword="" title="PRULink Funds Report 2018 (abridged version)" class="search-results__content--title">{release[0].heading}</a>
		<div class="search-results__content--desc">{release[0].description}</div>
	  </div>
	  <div class="col-md-2 search-results__btn d-none d-md-flex">
		<a href="/en/.galleries/PDF/fund/Abridge_PRULink-Funds-Report-2018.pdf" download="" data-keyword="" title="PRULink Funds Report 2018 (abridged version)" class="btn-arrow"><span class="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></a>
	  </div>
	</div>
	  
	  )
  
	}


  return(
       
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
											    		<div className="page-title__content">
														<h1 className="page-title__content--name"> Press <span className="color-red"> Releases </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                             Your trusted source for the latest news and happenings at our company
                                                        </div>
														</div>
											         
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={press} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>



<section data-pru-link="" class="section page-title-noimg">
				    <div class="page-title-noimg__wrapper">
          						<div class="container">
								   	<div class="row">
									   	<div class="col-xs-12 col-sm-8 offset-sm-2">
									      <h1 class="page-title-noimg__content--title clearSpaceMini"> 
                                           PRESS RELEASES
										  </h1>
                                    
										</div>
									</div>
								</div>
							</div>
</section>

{/*
<div className="container"> 
                       <div class="row">
							<div class="search-page__filter col-md-10 mx-auto" data-list-filter="">
								    <div class="search-page__filter--wrapper" data-list-filter="">
									    <div class="search-page__filter--inner">
										<div class="search-page__filter--label">
											Filter<span>by</span>
										</div>
									<div>
											<ul class="search-page__filter--items">
												<li class="item" data-key="/.categories/year/2024/">2024</li>
															<li class="item" data-key="/.categories/year/2023/">2023</li>
															<li class="item" data-key="/.categories/year/2022/">2022</li>
															<li class="item" data-key="/.categories/year/2021/">2021</li>
															<li class="item" data-key="/.categories/year/2020/">2020</li>
															<li class="item" data-key="/.categories/year/2019/">2019</li>
															<li class="item active" data-key="/.categories/year/2018/">2018</li>
															</ul>
										</div>
									</div>
								</div>
								</div>
							</div>
                       </div>

  */}

         <div className="container"> 
             {pressDetails}
                       
                       </div>



					   <footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default Press;