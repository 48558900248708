import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import policy from '../images/policy.jpg';
import deliver from '../images/Claim_Red.png';
import promise from '../images/promise.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';
import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
  
  


 function ClaimPromise() {

  window.scrollTo(0, 0)

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [description, setDescription] = useState("")
 const [hero, setHero] = useState("") 
 const[commitments, setCommitments] = useState([])
	
	
  
	useEffect( () => {
	  client
	.query({
	  query: gql`
	   query{
        pages {
            edges {
              node {
                claimPromise {
                    description
                  redheadingtext
                  blackheadingtext
                  commitmentcards {
                    commitmentbody
                    commitmentheading
                    commitmentimage {
                      mediaItemUrl
                    }
                  }
                  hero {
                    mediaItemUrl
                  }
                }
              }
            }
          }
	  }
	  `,
	})
	.then(async result => {
	  let commitmentHolder=[]
	   console.log(result)
	   //console.log(result.data.pages.edges[0].node.claimFAQ.claimsfaq[0])  

          setRedText(result.data.pages.edges[0].node.claimPromise.redheadingtext)
		  setBlackText(result.data.pages.edges[0].node.claimPromise.blackheadingtext)
		  setHero(result.data.pages.edges[0].node.claimPromise.hero.mediaItemUrl)
          setDescription(result.data.pages.edges[0].node.claimPromise.description)
	   
		   for(let x=0; x < result.data.pages.edges[0].node.claimPromise.commitmentcards.length; x++ ){
				
			  let commitmentDetails = [{image:result.data.pages.edges[0].node.claimPromise.commitmentcards[x].commitmentimage.mediaItemUrl, heading:result.data.pages.edges[0].node.claimPromise.commitmentcards[x].commitmentheading, body:result.data.pages.edges[0].node.claimPromise.commitmentcards[x].commitmentbody}]
  
			  commitmentHolder.push(commitmentDetails)
		   }
		   console.log(commitmentHolder)
		   setCommitments(commitmentHolder)
		   console.log(commitments)
	
	});
	
	}, [])   
	
	  
	let commitmentDetailsPack
	if(commitments.length > 0){ 
  
	  commitmentDetailsPack = commitments.map((commitment, i) => 
         <div className="card card-static inlineMargin paddingOff" style={{width: "20rem"}}>
             <img src={commitment[0].image} width="100%" className="border-radius-card" alt="..."/>
             <div className="card-body card-static__content">
             <h2 className="card-title card-static__content--title"> {commitment[0].heading}  </h2>
              <p className="card-text"> {commitment[0].body} </p>
           </div>

          </div>
	
	)


  }

  return(
       
<div className="">
<header> 
   <Navigation />
      </header>
<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name"> <strong> {blackText} </strong> <span className="color-red"> {redText} </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                        {description}
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>


<section className="component-rich-text gtm_article_section" data-pru-link="">
            <div className="container">
                

                <div className="component-rich-text__wrapper">
<div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
    <div className="col-12 col-md-4 text-center"> 
     <img src={deliver} /> 
    <h5 className="heavyFont"> How can we deliver </h5>

    </div> 
    <div className="col-12 col-md-8"> 
    <div className="rich-text">
    <h5 className="heavyFont"> Our Approach </h5>
<p className="editorial-body-copy">FOur claim process is simple and transparent. Our staff and agents are professionally trained to support you in a personal, customer friendly manner. </p>
<h5 className="heavyFont"> When you make a claim </h5>
  <p className="editorial-body-copy">
    We assess and pay each genuine claim promptly. We only ask for relevant information and give you updates on your claims progress when you need them. We explain our decisions simply and clearly.
     </p>
  </div>
    </div> 

</div>
</div>
</div>


            </div>
        </section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Our</strong> <span className="lightFont"> Commitments </span> </h1>

</div>

<div className="row inlineDisplay"> 
{commitmentDetailsPack}

</div>
</div>
</div>
</section>

<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default ClaimPromise;