import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import test from '../images/cover.jpg';
import Lana from '../images/lana.jpg';
import hospital from '../images/hospital.jpg';
import simulate from '../images/simulate.png';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import promise from '../images/promise.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Footer from './footer.js';
import Navigation from './nav';

import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
  

 function Claims() {

  window.scrollTo(0, 0)

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [hero, setHero] = useState("")
 const[faqs, setFAQ] = useState([]) 
 const [description, setDescription] = useState('')
 const[promise, setPromise] = useState([])
 const[assistance, setAssistance] = useState([])
 const[selfsubmitimagea, setSelfSubmitA] = useState("")
 const[selfsubmitimageb, setSelfSubmitB] = useState("")
 const[claimsFormImage, setClaimsFormImage] = useState("")
 const[claimsGlossaryImage, setClaimsGlossaryImage] = useState("")
 const[claimsFaqImage, setClaimsFaqImage] = useState("")
	
	
  
	useEffect( () => {
	  client
	.query({
	  query: gql`
	   query{
        pages {
            edges {
              node {
                claims {
                    blackheadingtext
                    redheadingtext
                  claimsfaq {
                    fieldGroupName
                    question
                    solution
                  }
                  claimsfaqimage {
                    mediaItemUrl
                  }
                  claimsformimage {
                    mediaItemUrl
                  }
                  claimsglossaryimage {
                    mediaItemUrl
                  }
                  claimspromise {
                    boldheadingtext
                    lightheadingtext
                    headingdescription
                    claimspromiseimage {
                      mediaItemUrl
                    }
                  }
                  headingdescription
                  heroimage {
                    mediaItemUrl
                  }
                  selfsubmitimagea {
                    mediaItemUrl
                  }
                  selfsubmitimageb {
                    mediaItemUrl
                  }
                  agentassistancesteps {
                    assistancecards {
                      cardbody
                      cardheading
                      cardimage {
                        mediaItemUrl
                      }
                    }
                  }
                }
              }
            }
          }
	  }
	  `,
	})
	.then(async result => {
	  let promiseHolder=[]
    let faqHolder = []
    let assistHolder = []
	   console.log(result)
	  console.log(result.data.pages.edges[4].node.claims.claimspromise[0])  

      setRedText(result.data.pages.edges[4].node.claims.redheadingtext)
		  setBlackText(result.data.pages.edges[4].node.claims.blackheadingtext)
		  setHero(result.data.pages.edges[4].node.claims.heroimage.mediaItemUrl)
          setDescription(result.data.pages.edges[4].node.claims.headingdescription)
          setSelfSubmitA(result.data.pages.edges[4].node.claims.selfsubmitimagea.mediaItemUrl)
          setSelfSubmitB(result.data.pages.edges[4].node.claims.selfsubmitimageb.mediaItemUrl)
          setClaimsFormImage(result.data.pages.edges[4].node.claims.claimsformimage.mediaItemUrl)
          setClaimsFaqImage(result.data.pages.edges[4].node.claims.claimsfaqimage.mediaItemUrl)
          setClaimsGlossaryImage(result.data.pages.edges[4].node.claims.claimsglossaryimage.mediaItemUrl)
          
	   
		   for(let x=0; x < result.data.pages.edges[4].node.claims.claimspromise.length; x++ ){
				
			  let promiseDetails = [{boldHeading:result.data.pages.edges[4].node.claims.claimspromise[x].boldheadingtext, lightHeading:result.data.pages.edges[4].node.claims.claimspromise[x].lightheadingtext, description:result.data.pages.edges[4].node.claims.claimspromise[x].headingdescription, image:result.data.pages.edges[4].node.claims.claimspromise[x].claimspromiseimage.mediaItemUrl}]
  
			  promiseHolder.push(promiseDetails)
		   }

       for(let x=0; x < result.data.pages.edges[4].node.claims.claimsfaq.length; x++ ){
				
			  let faqDetails = [{question:result.data.pages.edges[4].node.claims.claimsfaq[x].question, solution:result.data.pages.edges[4].node.claims.claimsfaq[x].solution }]
  
			  faqHolder.push(faqDetails)
		   }

       for(let x=0; x < result.data.pages.edges[4].node.claims.agentassistancesteps[0].assistancecards.length; x++ ){
				
			  let assistanceDetails = [{heading:result.data.pages.edges[4].node.claims.agentassistancesteps[0].assistancecards[x].cardheading, body:result.data.pages.edges[4].node.claims.agentassistancesteps[0].assistancecards[x].cardbody, image:result.data.pages.edges[4].node.claims.agentassistancesteps[0].assistancecards[x].cardimage.mediaItemUrl }]
  
			  assistHolder.push(assistanceDetails)
		   }


		   console.log(faqHolder)
		   setFAQ(faqHolder)
       setPromise(promiseHolder)
       setAssistance(assistHolder)

		   console.log(faqs)
	
	});
	
	}, [])   
	
	  
	let faqDetailsPack
	if(faqs.length > 0){ 
  
	  faqDetailsPack = faqs.map((faq, i) => 
      <div className="accordion-item accordion-comp">
    <h2 className="accordion-header accordion-comp-header">
      <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseClaim${i}`}  aria-expanded="true" aria-controls="collapseClaim">
    <h5 className="accordion-comp-title"> 
    {faq[0].question}
    </h5> 
      </button>
    </h2>
    <div id={`collapseClaim${i}`} className="accordion-collapse collapse" data-bs-parent="#accordionExample">
      <div className="accordion-body">
         {faq[0].solution}
        </div>
    </div>
  </div>
	
	)


  }


  let promiseDetailsPack
	if(promise.length > 0){ 
  
	  promiseDetailsPack = promise.map((promises, i) => 
<section className="section pd-100-60 basic-panel promotion" data-pru-link="">
<div className="row clearSpaceBottom1">
<div className="col-12 col-md-7"> 
                <div className="basic-panel__wrapper">
                    <div className="container">
                            <div className="basic-panel__outer">
                                <div className="basic-panel__outer--img">
                                    <picture>
                                        <source media="(max-width: 992px)" data-srcset=""/>
                                            <img className=" ls-is-cached lazyloaded" src={promises[0].image} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                    </picture>
                                </div>
                            </div>
                            </div>
                            </div>
                            </div>
                            <div className="col-12 col-md-5 paddingOff"> 
                            <div className="basic-panel__content-outer textGroupPosition">
                                <div className="container">
                                    <div className="row">
                                        <div className="basic-panel__content">
                                            <div className="basic-panel__content--wrapper">
                                                <h2 className="basic-panel__content--title">
                                                    <a name="easyswitch" id="easyswitch"></a>
                                                    <h1 className="bannerText basic-panel__content--title"> {promises[0].boldHeading} <span className="lightFont"> {promises[0].lightHeading} </span> </h1>
                                                    
                                                </h2>
                                                <p className=""> {promises[0].description} </p>


                                         

                                                        <Link className="btn-link gtm_PromotionBanner link" to="/claims/promise" title="Learn more"> Learn More <img src={arrow} /> </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                
           
                </div>
            </section>
	
	)


  }


  let assistanceDetailsPack
	if(assistance.length > 0){ 
  
	  assistanceDetailsPack = assistance.map((assist, i) => 
   <div className="card card-static col-12 inlineMargin paddingOff" style={{width: "22rem"}}>
 
  <img src={assist[0].image} width="100%" className="border-radius-card" alt="..."/>
 
  <div className="card-body card-static__content">
   
    <h2 className="card-title card-static__content--title"> {assist[0].heading} </h2>

    <p className="card-text"> {assist[0].body} </p>
    
       </div>

      </div>
	
	)


  }

  return(
  
    
  
<div className="">

<header> 
   <Navigation />
      </header>

<section data-pru-link="" class="pd-100-60 section page-title">
					  	<div class="page-title__page-wrapper row">
                          <div class="col-md-5">
							<div class="page-title__content-outer">
											<div class="container">
											  <div class="row">
											
													<div class="page-title__content">
														<h1 class="page-title__content--name">{blackText}<span class="color-red"> {redText}</span>
														</h1>
														<div class="page-title__content--desc"> {description} </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div class="offset-md-1 col-md-6">
										  <div class="page-title__img-outer">
											<div class="row">
										
											      <div class="page-title__img-inner">
													 <picture>
													   <img class="page-title--img lazyloaded img-fluid" src={hero} data-src="/export/sites/prudential-pamb/en/.galleries/images/men-business-discussion-666x500.jpg" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>



<div className="container widthOverride"> 

{promiseDetailsPack}

</div>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray3"></div>
<div className="col-12 offset-md-6 col-md-6 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Agent  </strong> <span className="lightFont"> assisted </span> </h1>
<p> Steps for your claims submission with the help of your agent </p>

</div>

<div className="row inlineDisplay"> 


{assistanceDetailsPack}

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>
<div className="col-12 col-md-6 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Self </strong> <span className="lightFont"> submit </span> </h1>
<p> Easy steps to submit your claims with us </p>

</div>
<div className="d-none d-sm-block">
    
<div className="row inlineDisplay "> 
<div className="card card-static inlineMargin col-10 col-md-6 halfWidth paddingOff" style={{width: "33rem"}}>
<Link to="/claims/forms" className="link"> 
    <div className="row"> 
    <div className="col-md-5 paddingOff"> 
    <img src={selfsubmitimagea}  className="webHeight border-radius-card1" alt="..." />
     </div>

    <div className="col-md-7"> 

     <div className="card-body card-static__content agentCard">
     <h2 className="card-title card-static__content--title"> Compile documents and complete claims form </h2>
     <p className="card-text"> Gather related reciepts and/or medical reports for your claim. </p>
     <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
     
     </div>

    </div>
</Link>
</div>

<div className="card card-static inlineMargin col-10 col-md-6 paddingOff" style={{width: "33rem"}}>
<Link to="/contact" className="link"> 
    <div className="row"> 
    <div className="col-md-5 paddingOff"> 
    <img src={selfsubmitimageb}  className="webHeight border-radius-card1" alt="..."/>
     </div>

    <div className="col-md-7"> 

     <div className="card-body card-static__content agentCard">
     <h2 className="card-title card-static__content--title"> Walk in to any of our branches </h2>
     <p className="card-text"> Submit your documents and complete claim forms at any of our branches.   </p>
     <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
     
     </div>

    </div>
    </Link>
</div>
</div>
</div>


<div className="row inlineDisplay d-block d-sm-none"> 
<div className="card card-static col-12 inlineMargin carouselCard paddingOff" >
<Link to="/claims/forms" className="link"> 
 <img src={selfsubmitimagea} width="100%" className="border-radius-card" alt="..."/>

 <div className="card-body card-static__content">
  
   <h2 className="card-title card-static__content--title"> Compile documents and complete claims form  </h2>

   <p className="card-text"> Gather related reciepts and/or medical reports for your claim. </p>
   
      </div>
      <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </Link>
     </div>

     <div className="card card-static col-12 inlineMargin carouselCard paddingOff">
    
 <Link to="/contact" className="link"> 
 <img src={selfsubmitimageb} width="100%" className="border-radius-card" alt="..."/>

 <div className="card-body card-static__content">
  
   <h2 className="card-title card-static__content--title"> Walk in to any of our branches </h2>

   <p className="card-text"> Submit your documents and complete claim forms at any of our branches. </p>
   
      </div>
      <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </Link>
     </div>
</div>


</div>
</div>

</section>



<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  How to Claim Insurance </strong> <span className="lightFont"> FAQ </span> </h1>

</div>
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray"></div>


<div className="col-12 inlineDisplay"> 

<div className="accordion" id="accordionExample">

  {faqDetailsPack}

</div>

</div>
</div>
</div>
</section>


<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray1"></div>
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong> Resources</strong> <span className="lightFont"> to assist your claims submission </span> </h1>
<p> Get more information, forms and FAQs on claims. </p>

</div>

<div className="row inlineDisplay"> 
<div className="card card-static inlineMargin carouselCard paddingOff">
<Link to="/claims/forms" className="link">  
  <img src={claimsFormImage} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Claims forms </h2>
    <p className="card-text">  </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </Link>
      </div>

      <div className="card card-static inlineMargin carouselCard paddingOff" >
      <Link to="/claims/faqs" className="link">  
  <img src={claimsFaqImage} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Claims FAQ's </h2>
    <p className="card-text"> </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
   </Link>
      </div>

      <div className="card card-static inlineMargin carouselCard paddingOff">
 <Link to="/claims/glossary" className="link">  
  <img src={claimsGlossaryImage} className="border-radius-card" width="100%" alt="..."/>
  <div className="card-body card-static__content">
    <h2 className="card-title card-static__content--title"> Claims glossary </h2>
    <p className="card-text"> </p>
    <div className="card-static__content--btn btn-arrow"><span className="icon icon-arrow-right"> <FontAwesomeIcon icon={faChevronRight} /> </span></div>
    </div>
    </Link>
      </div>

</div>
</div>
</div>
</section>


<footer>
         <Footer />
        </footer>
</div>

  )

 }

 export default Claims;