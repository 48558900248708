import "bootstrap/dist/css/bootstrap.css";
import {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faArrowRight, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import hospital from '../images/hospital.jpg';
import glossary from '../images/glossary.jpg';
import policy from '../images/policy.jpg';
import corp from '../images/corp.jpg';
import recurr from '../images/recurr.jpg';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import arrow from '../images/arrow.svg';
import Navigation from './nav';
import Footer from './footer.js';

import {
	InMemoryCache,
	ApolloClient,
	gql,
  } from '@apollo/client';
  
  const client = new ApolloClient({
	cache:  new InMemoryCache(),
	uri: "https://pzl-cms.inspireleadership.biz/graphql"
  });
  
  


 function Glossary() {

 window.scrollTo(0, 0)

 const [redText, setRedText] = useState("")
 const [blackText, setBlackText] = useState("")
 const [hero, setHero] = useState("") 
 const[glossary, setGlossary] = useState([])
	
	
  
	useEffect( () => {
	  client
	.query({
	  query: gql`
	   query{
		pages {
			edges {
			  node {
				claimGlossary {
				  blackheadingtext
				  redheadingtext
				  glossary {
					meaning
					term
				  }
				  heroimage {
					mediaItemUrl
				  }
				}
			  }
			}
		  }
	   }
	  `,
	})
	.then(async result => {
	  let glossaryHolder=[]
	   console.log(result)
	  console.log(result.data.pages.edges[3].node.claimGlossary.glossary[0])  

          setRedText(result.data.pages.edges[3].node.claimGlossary.redheadingtext)
		  setBlackText(result.data.pages.edges[3].node.claimGlossary.blackheadingtext)
		  setHero(result.data.pages.edges[3].node.claimGlossary.heroimage.mediaItemUrl)
	   
		   for(let x=0; x < result.data.pages.edges[3].node.claimGlossary.glossary.length; x++ ){
				
			  let glossaryDetails = [{term:result.data.pages.edges[3].node.claimGlossary.glossary[x].term, meaning:result.data.pages.edges[3].node.claimGlossary.glossary[x].meaning}]
  
			  glossaryHolder.push(glossaryDetails)
		   }
		   console.log(glossaryHolder)
		   setGlossary(glossaryHolder)
		   console.log(glossary)
	
	});
	
	}, [])   
	
	  
	let glossaryDetailsPack
	if(glossary.length > 0){ 
  
	  glossaryDetailsPack = glossary.map((glossary, i) => 
		<tr className="tableStyle">
	  <td className="tableDataStyle"> {glossary[0].term} </td>
	  <td className="tableDataStyle"> {glossary[0].meaning} </td>
	
	</tr>
	
	)
  
  }

  return(
       
<div className="">

<header> 
   <Navigation />
      </header>


<section data-pru-link="" className="pd-100-60 section page-title">
					  	<div className="page-title__page-wrapper row">
                          <div className="col-md-5">
							<div className="page-title__content-outer">
											<div className="container">
											  <div className="row">
											
													<div className="page-title__content">
														<h1 className="page-title__content--name">{blackText} <span className="color-red"> {redText} </span>
														</h1>
														<div className="page-title__content--desc"> 
                                                        Become knowldgeable about the key terms and definitions associated with managing your insurance account
                                                        </div>
														</div>
											
											  </div>
											</div>
										  </div>
                                          </div>
                                          <div className="offset-md-1 col-md-6">
										  <div className="page-title__img-outer">
											<div className="row">
										
											      <div className="page-title__img-inner">
													 <picture>
													   <img className="page-title--img lazyloaded" src={hero} width="100%" alt="Enterprise Insurance "/>
												     </picture>
												  </div>
							
											</div>
										  </div>
                                          </div>
								         </div>
</section>




<section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
<div className="container"> 
<div className="col-12 offset-md-7 col-md-5 paddingBottom"> 
<h1 className="basic-panel__content--title"> <strong>  Learn the terms </strong> <span className="lightFont"> in claims </span> </h1>

</div>



<div className="col-12 inlineDisplay"> 
<div className="component-rich-text__wrapper">
<div className="bk-blue-gray2"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h4 className="heavyFont clearSpaceMini"> <strong> Know what you are looking for? </strong>  </h4>

<table>
  <tr className="tableStyle">
    <th className="heavyFont redText" style={{width:"40%"}}> Term </th>
    <th className="heavyFont redText"style={{width:"60%"}}> Definition </th>
    
  </tr>
{glossaryDetailsPack}
</table>
  </div>
</div>
</div>
</div>


</div>

</div>
</section>



<footer>
         <Footer />
        </footer>

</div>

  )

 }

 export default Glossary;