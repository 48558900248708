import test from '../images/pzl-career.png';
import test1 from '../images/pzl-c-mob.png';
import testi from '../images/testi.jpg';
import Henry from '../images/henry.jpg'
import Carousel from 'react-bootstrap/Carousel';
import Footer from './footer.js';
import Navigation from './nav';




function Careers() { 




    return (

   <div> 

      <div className="container-fluid paddingOff">
                  <header> 
             <Navigation />
                </header>
          <div className="clearSpaceBottom d-none d-sm-block"> 
                       <Carousel className="landingCarousel" controls={false} indicators={false}>
                       <Carousel.Item className="landingCarousel" >
        <img src={test} width="100%" className="landingCarousel" />
        <Carousel.Caption className="landingCarouselCaption">
          <h1 className="bannerText2"> Why Prudential ? </h1>
          <p> Prudential Zenith Life is a leading insurance company committed to providing innovative insurance solutions to individuals and corporate businesses in Nigeria. With a strong heritage and a focus on customer satisfaction,
              we have been a trusted partner for generations.
 </p>

        </Carousel.Caption>
      </Carousel.Item>
              </Carousel>
              
              </div>
              <div className="clearSpaceBottom d-block d-sm-none"> 
     <Carousel className="landingCarousel" controls={false} indicators={false}>
      <Carousel.Item className="landingCarousel" >
        <img src={test1} width="100%" className="landingCarousel" />
        <Carousel.Caption className="landingCarouselCaption">
          <h1 className="bannerText2"> Why Prudential ? </h1>
          <p> Prudential Zenith Life is a leading insurance company committed to providing innovative insurance solutions to individuals and corporate businesses in Nigeria. With a strong heritage and a focus on customer satisfaction,
              we have been a trusted partner for generations.
 </p>

        </Carousel.Caption>
      </Carousel.Item>
              </Carousel>
              
              </div>
          
          <div className="container widthOverride"> 
          
          <section className="section pd-100-60 basic-panel promotion" data-pru-link="">
  <div className="row clearSpaceBottom1">
  
  <div className="col-12 col-md-7"> 
                  <div className="basic-panel__wrapper">
                      <div className="container">
                              <div className="basic-panel__outer">
                                  <div className="basic-panel__outer--img">
                                      <picture>
                                          <source media="(max-width: 992px)" data-srcset=""/>
                                              <img className=" ls-is-cached lazyloaded" src={testi} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                      </picture>
                                  </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div className="col-12 col-md-5"> 
                              <div className="basic-panel__content-outer textGroupPosition">
                                  <div className="container">
                                      <div className="row">
                                          <div className="basic-panel__content">
                                              <div className="basic-panel__content--wrapper">
                                                  <h2 className="basic-panel__content--title">
                                                      <a name="easyswitch" id="easyswitch"></a>
                                                      <h1 className="bannerText"> Prudential  <span className="lightFont"> Promise </span> </h1>
                                                      
                                                  </h2>
                                                  <p> At Prudential Zenith Life, we believe in fostering a positive and inclusive work environment. Our values of delivering on our commitment, entrepreneurial spirit and collaboration guide our interactions with colleagues and customers. 
                                                      We encourage innovation, creativity, and personal growth. </p>
  
                                           
  
                                            
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                  
             
                  </div>
              </section>

 <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
           <div className="container">
      

          <div className="component-rich-text__wrapper">
        <div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h2>
  <span> Unlocking the Power in Diversity & Inclusion:</span>
   <span className="lightFont">Equal Opportunity  </span>
   </h2>
   <br/>

<p className="editorial-body-copy">Provides equal opportunities regardless of gender, ethnicity, disability status, age, religion, caring responsibilities, or sexual orientation. Prudential believes that diversity of skillsets, competencies, backgrounds, experience, and thoughts enriches the organization and are vital to its success, today and in the future.  </p>
<p className="editorial-body-copy"> The Board has therefore decided to make diversity and inclusion one of the strategic objectives for Prudential. We maintain an inclusive culture that is sensitive to the needs of employees. We make appropriate disability adjustments as required and provide training and career development opportunities for all, with fair consideration and encouragement to all applicants with suitable aptitude and abilities.
 </p>
</div>
</div>
</div>
</div>


  </div>
</section>

<section className="section pd-100-60 basic-panel promotion" data-pru-link="">
  <div className="row clearSpaceBottom1">
  
  <div className="col-12 col-md-7"> 
                  <div className="basic-panel__wrapper">
                      <div className="container">
                              <div className="basic-panel__outer">
                                  <div className="basic-panel__outer--img">
                                      <picture>
                                          <source media="(max-width: 992px)" data-srcset=""/>
                                              <img className=" ls-is-cached lazyloaded" src={Henry} data-src="/-/media/project/prudential/images/home/prushield-easyswitch-for-better-health-insurance.webp" alt="" width="566" height="377"/>
                                      </picture>
                                  </div>
                              </div>
                              </div>
                              </div>
                              </div>
                              <div className="col-12 col-md-5"> 
                              <div className="basic-panel__content-outer textGroupPosition">
                                  <div className="container">
                                      <div className="row">
                                          <div className="basic-panel__content">
                                              <div className="basic-panel__content--wrapper">
                                                  <h2 className="basic-panel__content--title">
                                                      <a name="easyswitch" id="easyswitch"></a>
                                                      <h1 className="bannerText"> Employee  <span className="lightFont"> Testimonials </span> </h1>
                                                      
                                                  </h2>
                                                  <p> 
                                                    "Working at Prudential Zenith Life has been a rewarding experience. The company provides ample opportunities for growth and development, and the supportive work environment makes it a great place to be."
                                                   <br/> - Henry Erugo
                                                  </p>
  
                                           
  
                                            
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              </div>
                  
             
                  </div>
              </section>

              <section className="component-rich-text  pd-100-60 gtm_article_section" data-pru-link="">
           <div className="container">
      

          <div className="component-rich-text__wrapper">
        <div className="bk-blue-gray-intro"></div>
<div className="container-rich-text">
<div className="row">
<div className="rich-text">
<h2>
  <span> How to apply </span>
   <span className="lightFont"> </span>
   </h2>
   <br/>

<p className="editorial-body-copy"> Are you interested in building your career with us? Submit your CV to careers@prudentialzenith.com
 </p>

</div>
</div>
</div>
</div>


  </div>
</section>
          
          </div>

          </div>  

          <footer>
         <Footer />
        </footer>
          
          </div>
    )
}


export default Careers